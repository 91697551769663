<script setup lang="ts">
declare const window: {
  moli: {
    que: any[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    refreshAdSlot: Function;
  };
  googletag: {
    cmd: any[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    pubads: Function;
  };
};

/********************
 * COMPOSITIONS      *
 ********************/
const attrs = useAttrs();

/********************
 * REFS & VARS       *
 ********************/
const isRendered = ref(false);

/********************
 * FUNCTIONS         *
 ********************/
function slotRendered(event) {
  const slot = event.slot;
  // don't render for excluded advertiser ids
  // (!!event.advertiserId &&
  //   disallowedAdvertiserIds.includes(event.advertiserId)) ||
  if (slot.getSlotElementId() === attrs.id) {
    isRendered.value = !event.isEmpty;
  }
}

/********************
 * HOOKS             *
 ********************/
onMounted(() => {
  const id = attrs.id;

  if (id) {
    window.moli = window.moli || { que: [] };
    try {
      window.moli.que.push(() => {
        window.moli.refreshAdSlot(id).catch(() => {});
      });
    } catch (_e) {
      // Error is okay
    }
  }

  if (id === 'camping_floorad' || id === 'camping_mobile_stickyad') {
    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(() => window.googletag.pubads().addEventListener('slotRenderEnded', slotRendered));
  }
});

onBeforeUnmount(() => {
  window.googletag?.pubads?.().removeEventListener?.('slotRenderEnded', slotRendered);
});
</script>

<template>
  <div
    class="ad ci-ad-slot"
    :class="{ 'ad--rendered': isRendered }"
  />
</template>
